import React from "react";

const ExpCollaboratorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#64748B"
      d="M1.875 6.645l6.75 3.9c.114.066.244.1.375.1.132 0 .261-.034.375-.1l6.75-3.9c.114-.065.208-.16.274-.273.066-.113.1-.241.101-.372 0-.132-.034-.262-.1-.377-.065-.114-.16-.21-.275-.276l-6.75-3.892c-.114-.066-.243-.1-.375-.1-.131 0-.26.034-.375.1l-6.75 3.892c-.114.067-.21.162-.275.276-.066.115-.1.245-.1.377.001.13.036.26.102.372.066.114.16.208.273.273zM9 3l5.25 3L9 9 3.75 6 9 3zm6.375 5.377L9 12 2.625 8.347c-.085-.05-.18-.081-.278-.094-.098-.013-.198-.006-.293.02-.096.025-.185.07-.263.13-.079.06-.144.136-.193.222-.098.172-.124.376-.072.567.052.191.178.354.35.453l6.75 3.9c.113.066.243.1.374.1.132 0 .261-.034.375-.1l6.75-3.9c.172-.099.297-.262.35-.453.051-.19.026-.395-.072-.567-.05-.086-.115-.161-.193-.222-.078-.06-.168-.105-.263-.13-.096-.026-.195-.033-.293-.02-.098.013-.193.045-.279.094v.03zm0 3L9 15l-6.375-3.652c-.085-.05-.18-.082-.278-.095-.098-.013-.198-.006-.293.02-.096.025-.185.07-.263.13-.079.06-.144.136-.193.222-.098.172-.124.376-.072.567.052.191.178.354.35.453l6.75 3.9c.113.066.243.1.374.1.132 0 .261-.034.375-.1l6.75-3.9c.172-.099.297-.262.35-.453.051-.19.026-.395-.072-.567-.05-.086-.115-.161-.193-.222-.078-.06-.168-.105-.263-.13-.096-.026-.195-.033-.293-.02-.098.013-.193.045-.279.095v.03z"
    />
  </svg>
);

export default ExpCollaboratorIcon;
