/* eslint-disable react/prop-types */
import React, { memo } from "react";
import NavBar from "components/NavBar";
import { Container } from "./styles";

const LayoutAdmin = ({ children }) => {
  return (
    <Container>
      <NavBar />
      <main>{children}</main>
    </Container>
  );
};

export default memo(LayoutAdmin);
