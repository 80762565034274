import React from "react";

const CopyIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#EEF2FF"
      fillRule="evenodd"
      d="M5.25 7.5c0-1.243 1.007-2.25 2.25-2.25h6c1.243 0 2.25 1.007 2.25 2.25v6c0 1.243-1.007 2.25-2.25 2.25h-6c-1.243 0-2.25-1.007-2.25-2.25v-6z"
      clipRule="evenodd"
    />
    <path
      fill="#EEF2FF"
      fillRule="evenodd"
      d="M2.909 2.909c.422-.422.994-.659 1.591-.659h6c.597 0 1.169.237 1.591.659.422.422.659.994.659 1.591V6c0 .414-.336.75-.75.75s-.75-.336-.75-.75V4.5c0-.199-.079-.39-.22-.53-.14-.141-.331-.22-.53-.22h-6c-.199 0-.39.079-.53.22-.141.14-.22.331-.22.53v6c0 .199.079.39.22.53.14.141.331.22.53.22H6c.414 0 .75.336.75.75s-.336.75-.75.75H4.5c-.597 0-1.169-.237-1.591-.659-.422-.422-.659-.994-.659-1.591v-6c0-.597.237-1.169.659-1.591z"
      clipRule="evenodd"
    />
  </svg>
);

export default CopyIcon;
