import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 210px;
  border-right: solid 1px #334155;
  height: 90vh;
  justify-content: flex-start;
  padding-left: 30px;
  svg {
    margin-bottom: 35px;
    cursor: pointer;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  height: 55px;
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }

  div {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
`;

export const Border = styled.div`
  width: 5px;
  height: 48px;
  flex-grow: 0;
  border-radius: 5px;
  border: solid 0.5px #34d399;
  background-color: #10b981;
  position: relative;
  left: 1.5%;
`;

export const Label = styled.span`
  margin: 3px 0 2px 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: ${({ current }) => (current ? "#eef2ff" : "#94a3b8")};
`;
