/* eslint-disable react/display-name */
import React from "react";
import Theme from "theme/global";
import Navigation from "routes/Navigation";
import UserProvider from "providers/UserProvider";
import QAProvider from "providers/PageQAProvider";

export default () => (
  <UserProvider>
    <QAProvider>
      <Theme />
      <Navigation />
    </QAProvider>
  </UserProvider>
);
