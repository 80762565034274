/* eslint-disable react/display-name */
import React, { Suspense, lazy, useContext } from "react";
import history from "helpers/history";
import { Router, Switch, Route } from "react-router-dom";
import LayoutAdmin from "components/LayoutAdmin";
import LoadingLayout from "components/LoadingLayout";
import Redirection from "helpers/Redirection";
import { IntlProvider } from "react-intl";
import { LocaleContext } from "helpers/LocaleContext";
import fr from "translations/fr";
import en from "translations/en";

const Login = lazy(() => import("features/Login"));
const Dashboard = lazy(() => import("features/Dashboard"));
const DetailsUser = lazy(() => import("features/User/DetailsUser"));
const pageQA = lazy(() => import("features/QA"));
const DetailsCapability = lazy(() =>
  import("features/QA/Capabilities/DetailsCapability")
);
const DetailsQuestion = lazy(() =>
  import("features/QA/Questions/DetailsQuestion")
);
const DetailsSection = lazy(() =>
  import("features/QA/Sections/DetailsSection")
);
const DetailAnswers = lazy(() => import("features/QA/Answers/DetailsAnswers"));
const NoMatchPage = () => <h3>404 - Not found</h3>;

const messages = {
  en,
  fr,
};

export default () => {
  const [locale] = useContext(LocaleContext);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Router history={history}>
        <Switch>
          <Suspense fallback={<LoadingLayout />}>
            <Route exact path="/admin" component={Login} />
            <Redirection>
              <Switch>
                <LayoutAdmin>
                  <Route exact path="/admin/dashboard" component={Dashboard} />
                  <Route
                    exact
                    path="/admin/dashboard/:idUser"
                    component={DetailsUser}
                  />
                  <Route exact path="/admin/pageQA" component={pageQA} />
                  <Route
                    exact
                    path="/admin/pageQA/:idSection"
                    component={DetailsSection}
                  />
                  <Route
                    exact
                    path="/admin/pageQA/:idSection/:idCapability"
                    component={DetailsCapability}
                  />
                  <Route
                    exact
                    path="/admin/pageQA/:idSection/:idCapability/:idQuestion"
                    component={DetailsQuestion}
                  />
                  <Route
                    exact
                    path="/admin/pageQA/:idSection/:idCapability/:idQuestion/:idAnswer"
                    component={DetailAnswers}
                  />
                </LayoutAdmin>
                <Route path="*" component={NoMatchPage} />
              </Switch>
            </Redirection>
          </Suspense>
        </Switch>
      </Router>
    </IntlProvider>
  );
};
