/* eslint-disable react/prop-types */
import React from "react";

const DashboardIcon = ({ current }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={current ? "#eef2ff" : "#334155"}
      fillRule="evenodd"
      d="M3 5c0-.53.21-1.04.586-1.414C3.96 3.21 4.47 3 5 3h14c.53 0 1.04.21 1.414.586C20.79 3.96 21 4.47 21 5v14c0 .53-.21 1.04-.586 1.414C20.04 20.79 19.53 21 19 21H5c-.53 0-1.04-.21-1.414-.586C3.21 20.04 3 19.53 3 19V5zm16 0H5v14h14V5zm-7 2c.265 0 .52.105.707.293.188.187.293.442.293.707v8c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293-.265 0-.52-.105-.707-.293C11.105 16.52 11 16.265 11 16V8c0-.265.105-.52.293-.707C11.48 7.105 11.735 7 12 7zm4 2c.265 0 .52.105.707.293.188.187.293.442.293.707v6c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293-.265 0-.52-.105-.707-.293C15.105 16.52 15 16.265 15 16v-6c0-.265.105-.52.293-.707C15.48 9.105 15.735 9 16 9zm-8 2c.265 0 .52.105.707.293.188.187.293.442.293.707v4c0 .265-.105.52-.293.707C8.52 16.895 8.265 17 8 17c-.265 0-.52-.105-.707-.293C7.105 16.52 7 16.265 7 16v-4c0-.265.105-.52.293-.707C7.48 11.105 7.735 11 8 11z"
      clipRule="evenodd"
    />
  </svg>
);

export default DashboardIcon;
