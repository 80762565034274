/* eslint-disable react/prop-types */
import React from "react";

const RapportIcon = ({ current }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={current ? "#eef2ff" : "#334155"}
      d="M18 18c-.265 0-.52.105-.707.293-.188.187-.293.442-.293.707 0 .265-.105.52-.293.707-.187.188-.442.293-.707.293H6c-.265 0-.52-.105-.707-.293C5.105 19.52 5 19.265 5 19V5c0-.265.105-.52.293-.707C5.48 4.105 5.735 4 6 4h5v3c0 .796.316 1.559.879 2.121.562.563 1.325.879 2.121.879h3v1c0 .265.105.52.293.707.187.188.442.293.707.293.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707V8.94c-.01-.092-.03-.182-.06-.27.004-.03.004-.06 0-.09-.048-.103-.112-.197-.19-.28l-6-6c-.083-.078-.177-.142-.28-.19h-.1c-.094-.05-.195-.088-.3-.11H6c-.796 0-1.559.316-2.121.879C3.316 3.44 3 4.204 3 5v14c0 .796.316 1.559.879 2.121C4.44 21.684 5.204 22 6 22h10c.796 0 1.559-.316 2.121-.879.563-.562.879-1.325.879-2.121 0-.265-.105-.52-.293-.707C18.52 18.105 18.265 18 18 18zM13 5.41L15.59 8H14c-.265 0-.52-.105-.707-.293C13.105 7.52 13 7.265 13 7V5.41zM20 14h-2.5c-.132 0-.262.024-.384.074-.122.05-.233.123-.326.216l-1.24 1.25-2.8-3.2c-.09-.103-.2-.187-.324-.246-.124-.058-.258-.091-.395-.096-.137-.005-.273.019-.4.07-.128.05-.244.125-.341.222L9.59 14H8c-.265 0-.52.105-.707.293C7.105 14.48 7 14.735 7 15c0 .265.105.52.293.707.187.188.442.293.707.293h2c.132 0 .262-.024.384-.074.122-.05.233-.123.326-.216L12 14.46l2.8 3.2c.09.103.2.186.325.245.124.058.258.09.395.095.132 0 .262-.024.384-.074.122-.05.233-.123.326-.216L17.91 16H20c.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707 0-.265-.105-.52-.293-.707C20.52 14.105 20.265 14 20 14z"
    />
  </svg>
);

export default RapportIcon;
