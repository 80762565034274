/* eslint-disable react/prop-types */
import React from "react";

const AnalysisIcon = ({ current }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill={current ? "#eef2ff" : "#334155"}
      d="M10 20c-2.652 0-5.196-1.054-7.071-2.929C1.054 15.196 0 12.652 0 10s1.054-5.196 2.929-7.071C4.804 1.054 7.348 0 10 0s5.196 1.054 7.071 2.929C18.946 4.804 20 7.348 20 10s-1.054 5.196-2.929 7.071C15.196 18.946 12.652 20 10 20zm-5.6-4.29c1.651-1.124 3.602-1.726 5.6-1.726 1.998 0 3.949.602 5.6 1.726 1.135-1.112 1.913-2.538 2.234-4.094.322-1.556.173-3.172-.428-4.644-.6-1.47-1.626-2.73-2.945-3.616-1.319-.886-2.872-1.36-4.461-1.36-1.59 0-3.142.474-4.461 1.36-1.32.886-2.344 2.145-2.945 3.616-.601 1.472-.75 3.088-.428 4.644.321 1.556 1.1 2.982 2.234 4.094zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02c.115.425.086.877-.082 1.284-.168.407-.466.748-.848.968-.38.22-.824.31-1.261.252-.437-.057-.843-.257-1.154-.569-.312-.311-.512-.717-.569-1.154-.057-.437.031-.88.252-1.262.22-.38.56-.679.968-.847.407-.168.859-.197 1.284-.082z"
    />
  </svg>
);

export default AnalysisIcon;
