import React from "react";

const UploadIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill="#64748B"
      d="M40 17.88c-.02-.184-.061-.365-.12-.54v-.18c-.096-.206-.224-.395-.38-.56l-12-12c-.165-.156-.354-.284-.56-.38h-.2c-.194-.104-.403-.178-.62-.22H14c-1.591 0-3.117.632-4.243 1.757C8.632 6.883 8 8.41 8 10v28c0 1.591.632 3.117 1.757 4.243C10.883 43.368 12.41 44 14 44h20c1.591 0 3.117-.632 4.243-1.757C39.368 41.117 40 39.59 40 38V17.88zm-12-7.06L33.18 16H30c-.53 0-1.04-.21-1.414-.586C28.21 15.04 28 14.53 28 14v-3.18zM36 38c0 .53-.21 1.04-.586 1.414C35.04 39.79 34.53 40 34 40H14c-.53 0-1.04-.21-1.414-.586C12.21 39.04 12 38.53 12 38V10c0-.53.21-1.04.586-1.414C12.96 8.21 13.47 8 14 8h10v6c0 1.591.632 3.117 1.757 4.243C26.883 19.368 28.41 20 30 20h6v18z"
    />
    <path
      fill="#10B981"
      d="M24.76 21.16c.245.095.47.238.66.42l4 4c.187.186.336.407.438.65.101.245.154.506.154.77s-.053.526-.154.77c-.102.243-.25.464-.438.65-.186.188-.407.336-.651.438-.244.101-.505.154-.77.154-.263 0-.525-.053-.768-.154-.244-.102-.465-.25-.651-.438l-.58-.6V33c0 .53-.21 1.04-.586 1.414C25.04 34.79 24.53 35 24 35c-.53 0-1.04-.21-1.414-.586C22.21 34.04 22 33.531 22 33v-5.18l-.58.6c-.377.377-.888.588-1.42.588-.533 0-1.044-.211-1.42-.588-.377-.377-.588-.887-.588-1.42 0-.533.211-1.043.588-1.42l4-4c.19-.182.414-.325.66-.42.487-.2 1.033-.2 1.52 0z"
    />
  </svg>
);

export default UploadIcon;
