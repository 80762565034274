/* eslint-disable react/prop-types */
import React from "react";

const ContributorsIcon = ({ current }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={current ? "#eef2ff" : "#334155"}
      fillRule="evenodd"
      d="M9 11c.791 0 1.565-.235 2.222-.674.658-.44 1.17-1.064 1.473-1.795.303-.731.383-1.535.228-2.311-.154-.776-.535-1.489-1.095-2.048-.559-.56-1.272-.94-2.048-1.095-.776-.154-1.58-.075-2.31.227-.732.303-1.356.816-1.796 1.474C5.234 5.436 5 6.208 5 7c0 1.06.421 2.078 1.172 2.828C6.922 10.578 7.939 11 9 11zm0-6c.396 0 .782.117 1.111.337.329.22.585.532.737.898.151.365.19.767.114 1.155-.078.388-.268.745-.548 1.024-.28.28-.636.47-1.024.548-.388.077-.79.037-1.155-.114-.366-.152-.678-.408-.898-.737C7.117 7.782 7 7.396 7 7c0-.53.21-1.04.586-1.414C7.96 5.21 8.47 5 9 5zm8 8c.593 0 1.173-.176 1.667-.506.493-.33.878-.798 1.105-1.346.227-.548.286-1.151.17-1.733-.115-.582-.401-1.117-.82-1.536-.42-.42-.955-.706-1.537-.821-.582-.116-1.185-.057-1.733.17s-1.017.612-1.346 1.105C14.176 8.827 14 9.407 14 10c0 .796.316 1.559.879 2.121.562.563 1.325.879 2.121.879zm0-4c.198 0 .391.059.556.169.164.11.292.266.368.448.076.183.095.384.057.578-.039.194-.134.372-.274.512s-.318.235-.512.274c-.194.038-.395.019-.578-.057-.182-.076-.339-.204-.448-.368-.11-.165-.169-.358-.169-.556 0-.265.105-.52.293-.707C16.48 9.105 16.735 9 17 9zm-3.06 6.05c.876-.68 1.952-1.049 3.06-1.05 1.326 0 2.598.527 3.535 1.464C21.473 16.402 22 17.674 22 19c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293-.265 0-.52-.105-.707-.293C20.105 19.52 20 19.265 20 19c0-.563-.16-1.115-.46-1.592-.3-.477-.727-.86-1.234-1.105s-1.073-.343-1.633-.281c-.56.061-1.091.279-1.533.628.566 1.026.862 2.178.86 3.35 0 .265-.105.52-.293.707-.187.188-.442.293-.707.293-.265 0-.52-.105-.707-.293C14.105 20.52 14 20.265 14 20c0-1.326-.527-2.598-1.464-3.535C11.598 15.527 10.326 15 9 15s-2.598.527-3.536 1.465C4.527 17.402 4 18.674 4 20c0 .265-.105.52-.293.707C3.52 20.895 3.265 21 3 21c-.265 0-.52-.105-.707-.293C2.105 20.52 2 20.265 2 20c.002-1.383.413-2.733 1.182-3.883.768-1.149 1.86-2.044 3.137-2.574 1.277-.53 2.683-.669 4.039-.4 1.356.268 2.602.931 3.582 1.907z"
      clipRule="evenodd"
    />
  </svg>
);

export default ContributorsIcon;
