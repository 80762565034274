import React from "react";

const SlackIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd" clipPath="url(#clip0)" clipRule="evenodd">
      <path
        fill="#36C5F0"
        d="M8.799 0c-1.327.001-2.4 1.075-2.4 2.4 0 1.324 1.074 2.398 2.401 2.399h2.4V2.4C11.201 1.076 10.127.002 8.8 0c.001 0 .001 0 0 0zm0 6.4h-6.4C1.074 6.401 0 7.475 0 8.8c-.001 1.323 1.073 2.398 2.4 2.4h6.4c1.327-.001 2.401-1.075 2.4-2.4.001-1.325-1.073-2.399-2.4-2.4z"
      />
      <path
        fill="#2EB67D"
        d="M24 8.8c.001-1.325-1.073-2.399-2.4-2.4-1.327.001-2.402 1.075-2.4 2.4v2.4h2.4c1.326-.001 2.401-1.075 2.4-2.4zm-6.4 0V2.4c.001-1.324-1.073-2.398-2.4-2.4-1.326.001-2.4 1.075-2.4 2.4v6.4c-.002 1.323 1.073 2.398 2.4 2.4 1.326-.001 2.401-1.075 2.4-2.4z"
      />
      <path
        fill="#ECB22E"
        d="M15.2 24c1.326-.001 2.401-1.076 2.4-2.4.001-1.324-1.074-2.398-2.4-2.399h-2.4v2.4c-.002 1.323 1.073 2.397 2.4 2.399zm0-6.401h6.4c1.326-.001 2.401-1.075 2.4-2.4.002-1.323-1.073-2.398-2.4-2.4h-6.4c-1.326.001-2.4 1.075-2.4 2.4s1.073 2.399 2.4 2.4z"
      />
      <path
        fill="#E01E5A"
        d="M0 15.2c-.001 1.323 1.074 2.398 2.4 2.399 1.327-.001 2.402-1.076 2.4-2.4V12.8H2.4C1.074 12.801 0 13.875 0 15.2zm6.4 0v6.4c-.002 1.323 1.073 2.398 2.4 2.4 1.326-.001 2.4-1.076 2.4-2.4v-6.399c.002-1.324-1.073-2.398-2.4-2.4-1.327 0-2.4 1.075-2.4 2.399z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SlackIcon;
