import StrategyIcon from "assets/svg/strategy";
import ExpClientIcon from "assets/svg/expClient";
import TechnologyIcon from "assets/svg/technology";
import OperationIcon from "assets/svg/operation";
import ExpCollaboratorIcon from "assets/svg/expCollaborator";
import OrganCultureIcon from "assets/svg/organ&cultureIcon";
import GmailIcon from "assets/svg/gmail";
import WhatsAppIcon from "assets/svg/whatsapp";
import OutlookIcon from "assets/svg/outlook";
import SlackIcon from "assets/svg/slack";
import CopyIcon from "assets/svg/copy";
import UploadIcon from "assets/svg/upload";
import LogoIcon from "assets/svg/logo";
import ArrowIcon from "assets/svg/arrow";
import DashboardIcon from "assets/svg/dashboard";
import SettingIcon from "assets/svg/setting";
import RapportIcon from "assets/svg/rapport";
import ContributorsIcon from "assets/svg/contributors";
import AnalysisIcon from "assets/svg/analysis";
import SnapymIcon from "assets/svg/icon";
import ChevronRightIcon from "assets/svg/chevronRight";
import DirectionIcon from "assets/svg/direction";
import LogOutIcon from "assets/svg/logOut";
import CommentIcon from "assets/svg/comment";

export {
  StrategyIcon,
  ExpClientIcon,
  TechnologyIcon,
  OperationIcon,
  ExpCollaboratorIcon,
  OrganCultureIcon,
  GmailIcon,
  WhatsAppIcon,
  SlackIcon,
  OutlookIcon,
  CopyIcon,
  UploadIcon,
  LogoIcon,
  ArrowIcon,
  DashboardIcon,
  SettingIcon,
  RapportIcon,
  ContributorsIcon,
  AnalysisIcon,
  SnapymIcon,
  ChevronRightIcon,
  DirectionIcon,
  LogOutIcon,
  CommentIcon,
};
