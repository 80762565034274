/* eslint-disable react/prop-types */
import React from "react";

const ArrowIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
  >
    <path
      fill={fill ? fill : "#475569"}
      d="M10.324 17.253l5.33-5.33c.856-.856 1.285-1.284 1.314-1.652.025-.32-.105-.631-.348-.839-.28-.24-.886-.24-2.098-.24H3.862c-1.21 0-1.817 0-2.097.24-.244.208-.373.52-.348.839.03.368.458.796 1.314 1.653l5.33 5.33c.396.395.594.593.822.668.201.065.418.065.618 0 .229-.075.427-.273.823-.669z"
    />
  </svg>
);

export default ArrowIcon;
