import React from "react";

const OperationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#FEF2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 20c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM7 8c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM17 8c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM7 8v8M9 18h6c.53 0 1.04-.21 1.414-.586C16.79 17.04 17 16.53 17 16v-5"
    />
    <path
      stroke="#FEF2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14 14l3-3 3 3"
    />
  </svg>
);

export default OperationIcon;
