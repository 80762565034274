import styled from "styled-components";

export const Container = styled.div`
  display: -webkit-box;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 12px;
  background-color: #0f172a;
  color: #fff;

  main {
    width: 80%;
    margin: auto;
    display: inline-block;
    justify-content: flex-start;
  }
`;
