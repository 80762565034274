/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import history from "helpers/history";
import { useLocation } from "react-router-dom";
import {
  SnapymIcon,
  DashboardIcon,
  AnalysisIcon,
  LogOutIcon,
} from "assets/svg";
import { Container, Section, Label, Border } from "./styles";

const NavBar = () => {
  const intl = useIntl();
  const [current, setCurrent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  const items = [
    {
      title: intl.formatMessage({ id: "navbar_title_dashboard" }),
      into: "/admin/dashboard",
      icon: <DashboardIcon />,
      iconFocused: <DashboardIcon current />,
    },
    {
      title: intl.formatMessage({ id: "navbar_title_pageQA" }),
      into: "/admin/pageQA",
      icon: <AnalysisIcon />,
      iconFocused: <AnalysisIcon current />,
    },
    {
      title: intl.formatMessage({ id: "navbar_title_logout" }),
      into: "/admin",
      icon: <LogOutIcon />,
      iconFocused: <LogOutIcon current />,
    },
  ];

  const handleChange = (into) => {
    if (into === "/admin") {
      localStorage.clear();
    }
    setCurrent(into);
    history.push(into);
  };

  const Item = ({ value }) => {
    return (
      <Section
        current={current === value.into}
        onClick={() => current !== value.into && handleChange(value.into)}
      >
        <div>
          {current === value.into ? value.iconFocused : value.icon}
          <Label current={current === value.into}>{value.title}</Label>
        </div>
        {current === value.into && <Border />}
      </Section>
    );
  };
  return (
    <Container>
      <Link to="/admin">
        <SnapymIcon />
      </Link>
      {items.map((item, key) => (
        <Item key={key} index={key} value={item} />
      ))}
    </Container>
  );
};
export default memo(NavBar);
