import React from "react";

const ExpClientIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FAF5FF"
      d="M17.09 2.82c-.92-.756-1.997-1.298-3.152-1.585-1.156-.287-2.361-.313-3.528-.075-1.554.314-2.979 1.082-4.095 2.207C5.198 4.492 4.44 5.924 4.14 7.48c-.22 1.167-.18 2.368.117 3.517.297 1.15.845 2.22 1.603 3.133.704.794 1.107 1.81 1.14 2.87v3c0 .796.316 1.559.879 2.121C8.44 22.684 9.204 23 10 23h4c.796 0 1.559-.316 2.121-.879.563-.562.879-1.325.879-2.121v-2.81c.034-1.17.464-2.296 1.22-3.19 1.325-1.64 1.95-3.735 1.738-5.832C19.747 6.07 18.716 4.14 17.09 2.8v.02zM15 20c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293h-4c-.265 0-.52-.105-.707-.293C9.105 20.52 9 20.265 9 20v-1h6v1zm1.67-7.24C15.664 13.953 15.078 15.442 15 17h-2v-3c0-.265-.105-.52-.293-.707C12.52 13.105 12.265 13 12 13c-.265 0-.52.105-.707.293-.188.187-.293.442-.293.707v3H9c-.026-1.532-.593-3.005-1.6-4.16-.664-.796-1.111-1.75-1.297-2.77-.185-1.02-.103-2.071.238-3.05.342-.98.93-1.853 1.71-2.537.78-.683 1.724-1.153 2.739-1.363.873-.18 1.774-.163 2.64.05.865.212 1.672.614 2.362 1.177.69.563 1.247 1.273 1.629 2.078.382.804.58 1.684.579 2.575.007 1.37-.463 2.7-1.33 3.76z"
    />
  </svg>
);

export default ExpClientIcon;
