import React from "react";

const SnapymIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="67"
    height="67"
    fill="none"
    viewBox="0 0 67 67"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth=".3"
        d="M20.592 25.223c1.098 1.098 1.098 2.877 0 3.975l-1.047 1.046c-1.793 1.793-1.793 4.7 0 6.492l1.047 1.046c1.098 1.098 1.098 2.877 0 3.975-1.098 1.097-2.878 1.097-3.975 0l-3.141-3.14c-2.832-2.832-2.832-7.422 0-10.254l3.14-3.14c1.098-1.097 2.878-1.097 3.976 0zm4.64-4.638c-1.099-1.097-1.099-2.877 0-3.974l3.14-3.14c2.832-2.831 7.424-2.831 10.256 0l3.14 3.14c1.099 1.098 1.099 2.877 0 3.974-1.097 1.098-2.877 1.098-3.974 0l-1.047-1.046c-1.793-1.793-4.7-1.793-6.494 0l-1.047 1.046c-1.097 1.098-2.877 1.098-3.975 0zm5.021 26.856c1.793 1.793 4.7 1.793 6.494 0l1.047-1.047c1.097-1.097 2.877-1.097 3.975 0 1.098 1.098 1.098 2.877 0 3.975l-3.14 3.14c-2.833 2.831-7.425 2.831-10.257 0l-3.14-3.14c-1.099-1.098-1.099-2.877 0-3.974 1.097-1.098 2.877-1.098 3.974 0l1.047 1.046zm16.155-22.218c1.098-1.097 2.878-1.097 3.975 0l3.141 3.14c2.832 2.832 2.832 7.422 0 10.254l-3.14 3.14c-1.098 1.097-2.878 1.097-3.976 0-1.098-1.098-1.098-2.877 0-3.975l1.047-1.046c1.793-1.793 1.793-4.7 0-6.492l-1.047-1.046c-1.098-1.098-1.098-2.877 0-3.975z"
      />
      <ellipse
        cx="33.5"
        cy="33.491"
        fill="#10B981"
        stroke="#34D399"
        strokeWidth=".5"
        rx="6.909"
        ry="6.907"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0H47.37V47.37H0z"
          transform="rotate(44.992 16.75 40.446)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SnapymIcon;
