const initialState = {
  isLoggedIn: false,
  loading: false,
  userLoading: false,
  user: [],
  users: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        isLoggedIn: false,
        loading: true,
      };
    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        user: action.payload.user,
      };
    case "LOGIN_USER_FAILED":
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
      };
    case "GET_USERS":
      return {
        ...state,
        loading: true,
        users: [],
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case "GET_USERS_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "GET_USER":
      return {
        ...state,
        loading: true,
        user: [],
      };
    case "GET_USER_SUCCESS":
      return {
        ...state,
        userLoading: false,
        user: action.payload,
      };

    case "GET_USER_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "ADD_USER":
      return {
        ...state,
        loading: true,

      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        loading: false,


      };
    case "ADD_USER_FAILED":
      return {
        ...state,
        loading: true,
      };


    case "UPDATE_USER":
      return {
        ...state,
        loading: true,

      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        loading: false,


      };
    case "UPDATE_USER_FAILED":
      return {
        ...state,
        loading: true,
      };


    case "DELETE_USER":
      return {
        ...state,
        loading: true,

      }
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        loading: false,
      };

    case "DELETE_USER_FAILED":
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};
