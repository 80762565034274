import React from "react";

const ExpCollaboratorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#EFF6FF"
      d="M14.36 14.23c-.668.539-1.501.833-2.36.833s-1.691-.294-2.36-.833c-.204-.17-.467-.251-.732-.227-.264.024-.508.153-.678.357-.17.204-.251.468-.227.732.024.264.153.508.357.678 1.021.853 2.31 1.32 3.64 1.32s2.619-.467 3.64-1.32c.204-.17.333-.414.357-.678.024-.264-.057-.528-.227-.732-.084-.101-.187-.185-.303-.246-.117-.061-.244-.099-.375-.111-.264-.024-.528.057-.732.227zM9 11c.198 0 .391-.059.556-.168.164-.11.292-.267.368-.45.076-.182.095-.383.057-.577-.039-.194-.134-.372-.274-.512s-.318-.235-.512-.274c-.194-.038-.395-.019-.578.057-.182.076-.339.204-.448.368C8.059 9.61 8 9.802 8 10c0 .265.105.52.293.707.187.188.442.293.707.293zm6-2c-.198 0-.391.059-.556.169-.164.11-.292.266-.368.448-.076.183-.095.384-.057.578.039.194.134.372.274.512s.318.235.512.274c.194.038.395.019.578-.057.182-.076.339-.204.449-.368.11-.165.168-.358.168-.556 0-.265-.105-.52-.293-.707C15.52 9.105 15.265 9 15 9zm-3-7c-1.978 0-3.911.586-5.556 1.685-1.644 1.1-2.926 2.66-3.683 4.488-.757 1.827-.955 3.838-.569 5.778.386 1.94 1.338 3.721 2.737 5.12 1.398 1.399 3.18 2.351 5.12 2.737 1.94.386 3.95.188 5.778-.57 1.827-.756 3.389-2.038 4.488-3.682C21.413 15.91 22 13.978 22 12c0-1.313-.259-2.614-.761-3.827-.503-1.213-1.24-2.315-2.168-3.244-.929-.929-2.03-1.665-3.244-2.168C14.614 2.26 13.313 2 12 2zm0 18c-1.582 0-3.129-.47-4.445-1.348-1.315-.88-2.34-2.129-2.946-3.59-.606-1.462-.764-3.07-.455-4.623.308-1.552 1.07-2.977 2.19-4.096 1.118-1.119 2.543-1.88 4.095-2.19 1.552-.308 3.16-.15 4.623.456 1.461.605 2.71 1.63 3.59 2.946C19.53 8.871 20 10.418 20 12c0 2.122-.843 4.157-2.343 5.657S14.122 20 12 20z"
    />
  </svg>
);

export default ExpCollaboratorIcon;
