import React from "react";

const OrganCultureIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      stroke="#FAFAF9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
      d="M8.571 2.857h6.858v5.714H8.57V2.857zM14.286 15.429h6.857v5.714h-6.857v-5.714zM2.857 15.429h6.857v5.714H2.857v-5.714z"
      clipRule="evenodd"
    />
    <path
      stroke="#FAFAF9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
      d="M6.283 15.429V12h11.429v3.429M12 12V8.571"
    />
  </svg>
);

export default OrganCultureIcon;
