const initialState = {
  loading: false,
  shutters: [],
  checkingBack: false,
  loadedQuestions: [],
  capabilities: [],
  answers: [],
  shutter: [],
  capability: [],
  loadingShutters: false,
  capabilityloading: false,
  questionloading: false,
  question: [],
  answerloading: false,
  answer: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_SHUTTERS":
      return {
        ...state,
        loading: true,
        checkingBack: true,
      };
    case "GET_SHUTTERS_SUCCESS":
      return {
        ...state,
        loading: false,
        shutters: action.payload,
      };
    case "GET_SHUTTERS_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "POST_SHUTTERS_SUCCESS":
      return {
        ...state,
        shutters: [...state.shutters, ...action.payload],
      };
    case "UPDATE_SECTION_SUCCESS":
      return {
        ...state,
        shutter: action.payload,
      };
    case "LOAD_QUESTIONS":
      return {
        ...state,
        loading: true,
        loadedQuestions: [],
      };
    case "LOAD_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        loadedQuestions: action.payload,
      };
    case "LOAD_QUESTIONS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "GET_CAPABILITIES":
      return {
        ...state,
        loading: true,
        capabilities: [],
      };
    case "GET_CAPABILITIES_SUCCESS":
      return {
        ...state,
        loading: false,
        capabilities: action.payload,
      };
    case "GET_CAPABILITIES_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "POST_CAPABILITY_SUCCESS":
      return {
        ...state,
        capabilities: [...state.capabilities, ...action.payload],
      };
    case "UPDATE_CAPABILITY_SUCCESS":
      return {
        ...state,
        capability: action.payload,
      };
    case "GET_ANSWERS_BY_QUESTION_ID":
      return {
        ...state,
        loading: true,
        answers: [],
      };
    case "GET_ANSWERS_BY_QUESTION_ID_SUCCESS":
      return {
        ...state,
        loading: false,
        answers: action.payload.answers,
      };
    case "GET_ANSWERS_BY_QUESTION_ID_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "GET_SHUTTER_BY_ID":
      return {
        ...state,
        loadingShutters: true,
      };
    case "GET_SHUTTER_BY_ID_SUCCESS":
      return {
        ...state,
        loadingShutters: false,
        shutter: action.payload,
      };
    case "GET_SHUTTER_BY_ID_FAILED":
      return {
        ...state,
        loadingShutters: false,
      };
    case "GET_CAPABILITY_BY_ID":
      return {
        ...state,
        loading: true,
        capability: [],
      };
    case "GET_CAPABILITY_BY_ID_SUCCESS":
      return {
        ...state,
        capabilityloading: false,
        capability: action.payload,
      };
    case "GET_CAPABILITY_BY_ID_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "GET_QUESTION_BY_ID":
      return {
        ...state,
        loading: true,
        question: [],
      };
    case "GET_QUESTION_BY_ID_SUCCESS":
      return {
        ...state,
        question: action.payload,
      };
    case "GET_QUESTION_BY_ID_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "POST_QUESTION_SUCCESS":
      return {
        ...state,
        loadedQuestions: [...state.loadedQuestions, ...action.payload],
      };
    case "UPDATE_QUESTION_SUCCESS":
      return {
        ...state,
        question: action.payload,
      };
    case "GET_ANSWER_BY_ID":
      return {
        ...state,
        loading: true,
        answer: [],
      };
    case "GET_ANSWER_BY_ID_SUCCESS":
      return {
        ...state,
        answerloading: false,
        answer: action.payload.answer,
      };
    case "GET_ANSWER_BY_ID_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "POST_ANSWER_SUCCESS":
      return {
        ...state,
        answers: [...state.answers, ...action.payload],
      };
    case "UPDATE_ANSWER_SUCCESS":
      return {
        ...state,
        answer: action.payload,
      };
    default:
      return state;
  }
};
