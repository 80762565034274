/* eslint-disable react/prop-types */
import React from "react";

const CommentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#10B981"
      d="M10.833 9.167h-5c-.22 0-.433.087-.59.244C5.089 9.567 5 9.779 5 10c0 .221.088.433.244.59.156.155.368.243.59.243h5c.22 0 .432-.088.588-.244.157-.156.244-.368.244-.589 0-.221-.087-.433-.244-.59-.156-.156-.368-.243-.589-.243zm3.333-3.334H5.833c-.22 0-.433.088-.59.244-.155.157-.243.369-.243.59 0 .22.088.433.244.589.156.156.368.244.59.244h8.332c.221 0 .434-.088.59-.244.156-.156.244-.368.244-.59 0-.22-.088-.432-.244-.589-.156-.156-.369-.244-.59-.244zm1.667-4.166H4.167c-.664 0-1.3.263-1.768.732-.47.469-.732 1.105-.732 1.768V12.5c0 .663.263 1.299.732 1.768.469.469 1.104.732 1.768.732h9.658l3.083 3.092c.078.077.17.138.272.18.101.04.21.062.32.061.11.003.218-.02.317-.066.152-.063.282-.17.374-.305.092-.137.141-.297.142-.462V4.167c0-.663-.263-1.3-.732-1.768-.469-.469-1.105-.732-1.768-.732zm.833 13.825l-1.908-1.917c-.078-.077-.17-.138-.272-.18-.101-.041-.21-.062-.32-.062h-10c-.22 0-.432-.088-.589-.244-.156-.156-.244-.368-.244-.589V4.167c0-.221.088-.433.244-.59.157-.156.368-.244.59-.244h11.666c.221 0 .433.088.59.244.156.157.244.369.244.59v11.325z"
    />
  </svg>
);

export default CommentIcon;
