import React from "react";

const OutlookIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    fill="none"
    viewBox="0 0 26 24"
  >
    <path
      fill="#0364B8"
      d="M21.601 1.5h-12.9c-.28 0-.547.11-.745.308-.198.198-.309.466-.309.745V3.75L14.918 6l7.737-2.25V2.553c0-.28-.111-.547-.309-.745-.197-.197-.465-.308-.745-.308z"
    />
    <path
      fill="#0A2767"
      d="M23.893 13.054c.11-.345.197-.697.262-1.054 0-.088-.023-.174-.067-.25-.044-.075-.107-.138-.183-.182l-.01-.006-.002-.002-8.133-4.63c-.035-.023-.071-.044-.109-.062-.155-.077-.326-.118-.5-.118s-.345.04-.5.118c-.038.018-.074.039-.11.061L6.41 11.561l-.003.002-.009.005c-.076.044-.14.107-.183.183-.044.075-.067.161-.068.249.065.357.153.708.263 1.054l8.623 6.304 8.86-6.304z"
    />
    <path
      fill="#28A8EA"
      d="M18.152 3.75H12.9L11.383 6 12.9 8.25l5.252 4.5h4.502v-4.5l-4.502-4.5z"
    />
    <path fill="#0078D4" d="M7.647 3.75H12.9v4.5H7.647v-4.5z" />
    <path fill="#50D9FF" d="M18.152 3.75h4.502v4.5h-4.502v-4.5z" />
    <path
      fill="#0364B8"
      d="M18.153 12.75L12.9 8.25H7.647v4.5l5.253 4.5 8.128 1.326-2.875-5.826z"
    />
    <path fill="#0078D4" d="M12.9 8.25h5.252v4.5h-5.253v-4.5z" />
    <path fill="#064A8C" d="M7.647 12.75H12.9v4.5H7.647v-4.5z" />
    <path fill="#0078D4" d="M18.152 12.75h4.502v4.5h-4.502v-4.5z" />
    <path
      fill="#0A2767"
      d="M15.294 18.913l-8.85-6.45.372-.652 8.186 4.659c.05.02.104.03.159.028.054-.001.107-.014.156-.037l8.204-4.672.372.651-8.6 6.473z"
      opacity=".5"
    />
    <path
      fill="#1490DF"
      d="M23.905 12.433l-.01.006-.002.001-8.133 4.63c-.161.105-.347.166-.539.178-.192.012-.383-.026-.556-.109l2.831 3.796 6.194 1.348v.002c.144-.104.261-.24.342-.399.081-.158.123-.333.123-.511V12c0 .088-.023.174-.067.25-.044.076-.107.139-.183.183z"
    />
    <path
      fill="#000"
      d="M24.155 21.375v-.553l-7.49-4.267-.905.516c-.161.104-.347.165-.539.177-.192.012-.383-.026-.556-.109l2.831 3.796 6.194 1.348v.002c.144-.104.261-.24.342-.399.081-.158.123-.333.123-.511z"
      opacity=".05"
    />
    <path
      fill="#000"
      d="M24.118 21.662l-8.212-4.674-.146.082c-.161.104-.347.165-.539.177-.191.012-.383-.025-.556-.108l2.831 3.796 6.194 1.348v.002c.21-.152.361-.372.428-.622z"
      opacity=".1"
    />
    <path
      fill="#28A8EA"
      d="M6.41 12.443v-.008h-.008l-.023-.015c-.072-.044-.13-.106-.171-.18-.041-.073-.062-.156-.061-.24v9.375c0 .148.028.294.085.43.056.137.14.261.244.366.104.104.228.187.365.244.137.056.283.085.431.085H23.03c.093 0 .187-.014.277-.038.047-.008.093-.023.135-.044.016-.002.032-.007.046-.015.06-.026.119-.058.172-.098.015-.008.023-.008.03-.023l-17.28-9.84z"
    />
    <path
      fill="#000"
      d="M13.65 18.5V6.25c0-.265-.106-.519-.294-.706-.187-.188-.441-.293-.706-.294H7.67v5.592l-1.26.718-.005.002-.009.005c-.076.044-.139.107-.183.183-.043.076-.067.162-.067.25v.004V12v7.5h6.504c.265 0 .519-.106.706-.294.188-.187.293-.44.294-.706z"
      opacity=".1"
    />
    <path
      fill="#000"
      d="M12.9 19.25V7c-.001-.265-.107-.519-.294-.706-.188-.188-.441-.293-.707-.294H7.67v4.842l-1.26.718-.004.002-.009.005c-.076.044-.139.107-.183.183-.043.076-.067.162-.067.25v.004V12v8.25H11.9c.266 0 .52-.106.707-.294.187-.187.293-.44.294-.706z"
      opacity=".2"
    />
    <path
      fill="#000"
      d="M12.9 17.75V7c-.001-.265-.107-.519-.294-.706-.188-.188-.441-.293-.707-.294H7.67v4.842l-1.26.718-.004.002-.009.005c-.076.044-.139.107-.183.183-.043.076-.067.162-.067.25v.004V12v6.75H11.9c.266 0 .52-.106.707-.294.187-.187.293-.44.294-.706z"
      opacity=".2"
    />
    <path
      fill="#000"
      d="M12.15 17.75V7c-.001-.265-.107-.519-.294-.706-.188-.188-.442-.293-.707-.294H7.67v4.842l-1.26.718-.004.002-.009.005c-.076.044-.139.107-.183.183-.043.076-.067.162-.067.25v.004V12v6.75h5.003c.265 0 .52-.106.707-.294.187-.187.293-.44.293-.706z"
      opacity=".2"
    />
    <path
      fill="#0078D4"
      d="M1.144 6h10.005c.265 0 .52.105.707.293.188.187.293.442.293.707v10c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293H1.144c-.265 0-.52-.105-.707-.293C.249 17.52.144 17.265.144 17V7c0-.265.105-.52.293-.707C.624 6.105.879 6 1.144 6z"
    />
    <path
      fill="#fff"
      d="M3.045 10.101c.266-.566.695-1.04 1.232-1.36.595-.341 1.273-.51 1.959-.491.635-.014 1.26.147 1.81.465.518.308.934.76 1.2 1.3.288.595.432 1.25.42 1.912.014.691-.135 1.376-.433 2-.271.56-.7 1.027-1.235 1.345-.57.328-1.22.493-1.88.478-.648.015-1.289-.147-1.851-.47-.522-.309-.943-.76-1.214-1.302-.291-.587-.437-1.235-.426-1.89-.012-.685.13-1.364.418-1.987zm1.313 3.193c.142.358.382.669.693.896.317.222.696.336 1.083.326.411.016.817-.102 1.156-.336.307-.227.541-.54.673-.898.148-.4.22-.823.215-1.249.004-.43-.064-.857-.203-1.264-.122-.367-.348-.69-.65-.93-.33-.247-.734-.372-1.146-.355-.394-.01-.782.105-1.108.329-.317.227-.562.54-.708.902-.322.83-.324 1.75-.005 2.58z"
    />
  </svg>
);

export default OutlookIcon;
