import React from "react";

const WhatsAppIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#25D366"
        fillRule="evenodd"
        d="M20.503 3.488C18.247 1.24 15.247 0 12.051 0 5.465 0 .104 5.335.1 11.892c0 2.096.55 4.142 1.596 5.945L0 24l6.335-1.654c1.745.948 3.71 1.447 5.71 1.448h.005c6.585 0 11.946-5.335 11.949-11.893 0-3.177-1.24-6.165-3.497-8.413zm-8.452 18.297h-.004c-1.782 0-3.53-.477-5.055-1.378l-.363-.214-3.759.982 1.004-3.648-.236-.374c-.995-1.574-1.52-3.393-1.519-5.26.002-5.45 4.458-9.884 9.936-9.884 2.652 0 5.146 1.03 7.021 2.898 1.875 1.869 2.907 4.352 2.906 6.993-.002 5.45-4.457 9.885-9.931 9.885zm5.447-7.403c-.298-.149-1.766-.867-2.04-.967-.273-.099-.472-.148-.671.15-.2.297-.772.966-.946 1.164-.174.199-.348.223-.647.075-.298-.15-1.26-.463-2.4-1.475-.888-.788-1.487-1.761-1.662-2.059-.174-.297-.018-.458.131-.606.135-.133.299-.347.448-.52.15-.174.2-.298.299-.497.1-.198.05-.371-.025-.52-.075-.149-.672-1.612-.92-2.207-.243-.579-.49-.5-.672-.51-.174-.008-.374-.01-.573-.01-.199 0-.522.074-.796.372C6.75 7.069 5.98 7.788 5.98 9.25c0 1.462 1.07 2.875 1.22 3.074.149.198 2.105 3.2 5.1 4.487.712.306 1.268.489 1.702.626.715.226 1.366.194 1.88.117.574-.085 1.767-.719 2.015-1.413.25-.694.25-1.289.175-1.413-.075-.124-.274-.198-.573-.347z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default WhatsAppIcon;
