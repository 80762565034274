import React from "react";

const TechnologyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FFF7ED"
      d="M14 9h-4c-.265 0-.52.105-.707.293C9.105 9.48 9 9.735 9 10v4c0 .265.105.52.293.707.187.188.442.293.707.293h4c.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707v-4c0-.265-.105-.52-.293-.707C14.52 9.105 14.265 9 14 9zm-1 4h-2v-2h2v2zm8 0c.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707 0-.265-.105-.52-.293-.707C21.52 11.105 21.265 11 21 11h-2V9h2c.265 0 .52-.105.707-.293C21.895 8.52 22 8.265 22 8c0-.265-.105-.52-.293-.707C21.52 7.105 21.265 7 21 7h-2.18c-.15-.42-.39-.8-.705-1.115C17.8 5.57 17.42 5.329 17 5.18V3c0-.265-.105-.52-.293-.707C16.52 2.105 16.265 2 16 2c-.265 0-.52.105-.707.293C15.105 2.48 15 2.735 15 3v2h-2V3c0-.265-.105-.52-.293-.707C12.52 2.105 12.265 2 12 2c-.265 0-.52.105-.707.293C11.105 2.48 11 2.735 11 3v2H9V3c0-.265-.105-.52-.293-.707C8.52 2.105 8.265 2 8 2c-.265 0-.52.105-.707.293C7.105 2.48 7 2.735 7 3v2.18c-.42.15-.8.39-1.115.705C5.57 6.2 5.329 6.58 5.18 7H3c-.265 0-.52.105-.707.293C2.105 7.48 2 7.735 2 8c0 .265.105.52.293.707C2.48 8.895 2.735 9 3 9h2v2H3c-.265 0-.52.105-.707.293C2.105 11.48 2 11.735 2 12c0 .265.105.52.293.707.187.188.442.293.707.293h2v2H3c-.265 0-.52.105-.707.293C2.105 15.48 2 15.735 2 16c0 .265.105.52.293.707.187.188.442.293.707.293h2.18c.15.42.39.8.705 1.115.315.315.696.556 1.115.705V21c0 .265.105.52.293.707.187.188.442.293.707.293.265 0 .52-.105.707-.293C8.895 21.52 9 21.265 9 21v-2h2v2c0 .265.105.52.293.707.187.188.442.293.707.293.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707v-2h2v2c0 .265.105.52.293.707.187.188.442.293.707.293.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707v-2.18c.42-.15.8-.39 1.115-.705.315-.315.556-.696.705-1.115H21c.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707 0-.265-.105-.52-.293-.707C21.52 15.105 21.265 15 21 15h-2v-2h2zm-4 3c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293H8c-.265 0-.52-.105-.707-.293C7.105 16.52 7 16.265 7 16V8c0-.265.105-.52.293-.707C7.48 7.105 7.735 7 8 7h8c.265 0 .52.105.707.293.188.187.293.442.293.707v8z"
    />
  </svg>
);

export default TechnologyIcon;
