import React from "react";

const GmailIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#4285F4"
        d="M1.636 21.361h3.819V12.09L0 7.998v11.727c0 .904.732 1.636 1.636 1.636z"
      />
      <path
        fill="#34A853"
        d="M18.545 21.361h3.819c.904 0 1.636-.732 1.636-1.636V7.998l-5.455 4.09v9.273z"
      />
      <path
        fill="#FBBC04"
        d="M18.545 4.998v7.09L24 7.999V5.816c0-2.022-2.309-3.177-3.927-1.964l-1.528 1.146z"
      />
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M5.454 12.089V4.998L12 9.907l6.546-4.91v7.092l-6.546 4.909-6.545-4.91z"
        clipRule="evenodd"
      />
      <path
        fill="#C5221F"
        d="M0 5.816v2.182l5.455 4.09v-7.09L3.927 3.852C2.31 2.64 0 3.794 0 5.816z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GmailIcon;
