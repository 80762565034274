/* eslint-disable react/prop-types */
import setAuthToken from "helpers/setAuthToken";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const Redirection = ({ history, children, location }) => {
  const Token = localStorage.getItem("token");
  useEffect(() => {
    if (Token) {
      if (Date.now() >= jwtDecode(Token).exp * 1000) {
        history.push("/admin");
      } else {
        if (location.pathname === "/admin") {
          history.push("/admin/dashboard");
        }
        setAuthToken(Token);
      }
    } else {
      history.push("/admin");
    }
  }, [Token, history, location.pathname]);

  return Token ? <div>{children}</div> : null;
};

export default withRouter(Redirection);
