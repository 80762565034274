import React from "react";

const ChevronRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#10B981"
      fillRule="evenodd"
      d="M6.195 12.471C6.07 12.346 6 12.177 6 12s.07-.346.195-.471L9.724 8 6.195 4.471c-.121-.125-.188-.294-.187-.469.002-.174.072-.342.195-.465.124-.124.291-.194.466-.195.175-.002.343.065.469.187l4 4c.125.125.195.294.195.471s-.07.346-.195.471l-4 4c-.125.125-.295.196-.471.196-.177 0-.347-.07-.472-.196z"
      clipRule="evenodd"
    />
  </svg>
);

export default ChevronRightIcon;
